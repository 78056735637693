

import consumer from "./consumer"

consumer.subscriptions.create(
  'CalendarChannel',
  {
    received(data) {
      document.querySelector('#success').innerHTML = data['message1']
      document.querySelector('#distroy').innerHTML = data['message2']
    }
  }
)
