require("@rails/ujs").start()
var Turbolinks = require("turbolinks")
Turbolinks.start()
require("@rails/activestorage").start();
require("channels")
require("chartkick")
require("chart.js")
require("jquery")
import $ from 'jquery';
global.$ = $;
global.jQuery = $;

require("easy-autocomplete")
require("jquery-mask-plugin")

require('packs/jquery.dataTables.min')
import "@fortawesome/fontawesome-free/js/all";
import "bootstrap";
require( 'select2' );

require('packs/dev')
require('packs/custom')
require('packs/font-awesome')
require("packs/bootstrap-datepicker")

$.jMaskGlobals.watchDataMask = true;


