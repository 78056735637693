$(document).on("turbolinks:load", function() {
  $("#example11").DataTable({
  "language": {
    "lengthMenu": "Show _MENU_",
    search: "<i class='fa fa-search' aria-hidden='true'></i>",
    searchPlaceholder: "Search records.."
  }
});
  $("#example1").DataTable({
  "language": {
    "lengthMenu": "Show _MENU_",
    search: "<i class='fa fa-search' aria-hidden='true'></i>",
    searchPlaceholder: "Search records.."
  }
});
  $("#example2").DataTable({
  "language": {
    "lengthMenu": "Show _MENU_",
    search: "<i class='fa fa-search' aria-hidden='true'></i>",
    searchPlaceholder: "Search records.."
  }
});
  $("#example3").DataTable({
  "language": {
    "lengthMenu": "Show _MENU_",
    search: "<i class='fa fa-search' aria-hidden='true'></i>",
    searchPlaceholder: "Search records.."
  }
});
  $("#example4").DataTable({
  "language": {
    "lengthMenu": "Show _MENU_",
    search: "<i class='fa fa-search' aria-hidden='true'></i>",
    searchPlaceholder: "Search records.."
  }
});
});




