

import consumer from "./consumer"

consumer.subscriptions.create(
  'ReportsChannel',
  {
    received(data) {
      document.querySelector('.success_reports').innerHTML = data['message']
      // document.querySelector('#distroy').innerHTML = data['message2']
    }
  }
)
