import consumer from "./consumer"

consumer.subscriptions.create(
  'WebNotificationsChannel',
  {
    received(data) {
      document.querySelector('.data_web_notification').innerHTML = data['message']
      document.querySelector('.data_web_notification').setAttribute('class', 'd_link')
    }
  }
)
