/* Start Calender Section */
$(document).on("turbolinks:load", function() {
  $(".multi-item-carousel").carousel({
    interval: false,
  });
});

$(document).on("turbolinks:load", function() {
  $(".multi-item-carousel").on("slide.bs.carousel", function(e) {
    var $e = $(e.relatedTarget),
      itemsPerSlide = 4,
      totalItems = $(".carousel-item", this).length,
      $itemsContainer = $(".carousel-inner", this),
      it = itemsPerSlide - (totalItems - $e.index());
    if (it > 0) {
      for (var i = 0; i < it; i++) {
        $(".carousel-item", this).eq(e.direction == "left" ? i : 0).appendTo($itemsContainer);
      }
    }
  });
});

$(document).on("turbolinks:load", function() {
  $(".main_card_1, .main_card_1 .s_card").click(function() {
    $(".main_item").addClass("main_item_1");
    $(".main_item").removeClass("main_item_2 main_item_3 main_item_4");
  });
  $(".main_card_2, .main_card_2 .s_card").click(function() {
    $(".main_item").addClass("main_item_2");
    $(".main_item").removeClass("main_item_1 main_item_3 main_item_4");
  });
  $(".main_card_3, .main_card_3 .s_card").click(function() {
    $(".main_item").addClass("main_item_3");
    $(".main_item").removeClass("main_item_1 main_item_2 main_item_4");
  });
  $(".main_card_4, .main_card_4 .s_card").click(function() {
    $(".main_item").addClass("main_item_4");
    $(".main_item").removeClass("main_item_1 main_item_2 main_item_3");
  });
});
/* End Calender Section*/

/* Start Sidebar */
 $(document).on("turbolinks:load", function() {
  // $(".dropdown-item").css("margin-left", "0px");
  $("#sidebarCollapse").on("click", function() {
    $("#sidebar").toggleClass("active");
    // $(".dropdown-item").css("margin-left", "30px");
  });
});

$(document).on("turbolinks:load", function() {
  $(".calendar-date").click(function() {
    var selected_date = $(this).text();
    $(".calendar-year #selected_date").val(selected_date);
    $(".calendar-year").submit();
  });
  $(".calendar-month").click(function() {
    var selected_date = $(this).text();
    $(".calendar-year #selected_month").val(selected_date);
    $(".calendar-year").submit();
  });

  $(".select_calender_year").change(function() {
    $(".main_item").addClass("main_item_5");
    $(".main_item").removeClass("main_item_1 main_item_3 main_item_4 main_item_2");
    $(".calendar-year").submit();
  });

  $(".year_data").click(function() {
    $(".main_item").addClass("main_item_5");
    $(".main_item").removeClass("main_item_1 main_item_3 main_item_4 main_item_2");
    
    $(".calendar-year").submit();
  });

  $(".qa_client").change(function() {
    var selected_client = $('.qa_client').val();
    $(".client-selection-container #client_id").val(selected_client);
    $(".client-selection-container").submit();
    window.localStorage.clear();
    window.localStorage.setItem('selected_option', selected_client);
  });

});

/* End Sidebar */

/* Start Data Table JS */
$(document).on("turbolinks:load", function()  {
  $("#calendar").DataTable({
    "language": {
      "lengthMenu": "Show _MENU_",
      search: "<i class='fa fa-search' aria-hidden='true'></i>",
      searchPlaceholder: "Search records.."
    },
    "aaSorting": [[0,'desc']]
  });



 $('.inventory').DataTable( {
   "language": {
        "lengthMenu": "Show _MENU_",
        search: "<i class='fa fa-search' aria-hidden='true'></i>",
        searchPlaceholder: "Search records.."
      }
  } );

 $('#broadband_data').DataTable( {
   "language": {
        "lengthMenu": "Show _MENU_",
        search: "<i class='fa fa-search' aria-hidden='true'></i>",
        searchPlaceholder: "Search records.."
      }
  } );

 $('#wireless_data').DataTable( {
   "language": {
        "lengthMenu": "Show _MENU_",
        search: "<i class='fa fa-search' aria-hidden='true'></i>",
        searchPlaceholder: "Search records.."
      }
  } );



  $("#example").DataTable({
      "language": {
        "lengthMenu": "Show _MENU_",
        search: "<i class='fa fa-search' aria-hidden='true'></i>",
        searchPlaceholder: "Search records.."
      }
    });

  $("#account").DataTable({
    "ordering": false,
    "language": {
      "lengthMenu": "Show _MENU_",
      search: "<i class='fa fa-search' aria-hidden='true'></i>",
      searchPlaceholder: "Search records.."
    }
  });
  $("#wireless").DataTable({
      "lengthMenu": [5, 10, 25, 50, 75, 100],
      "lengthChange": false,
      "bFilter": true,
      "paging": true,
      "ordering": false,
      "info": true,
      "language": {
        "lengthMenu": "Show _MENU_",
        search: "<i class='fa fa-search' aria-hidden='true'></i>",
        searchPlaceholder: "Search records.."
      },
       "aaSorting": [[0,'desc']]
    })
  $("#wireless1").DataTable();
  $("#missing-bill").DataTable();
});



/* End Data Table JS */

/* Start expand and shrink Section */
$(document).on("turbolinks:load", function() {
  $(".expand_all").click(function() {
    if ($(this).data("closedAll")) {
      $("#main_accordion .collapse, .accord_btn, .sub_accord_btn").addClass("show");
      $(".accord_btn, .sub_accord_btn").attr("aria-expanded","true");
    } else {
      $("#main_accordion .collapse, .accord_btn, .sub_accord_btn").removeClass("show");
      $(".accord_btn, .sub_accord_btn").attr("aria-expanded","false");
    }
    $(this).data("closedAll", !$(this).data("closedAll"));
  });
  $(".expand_all").data("closedAll", true);
  $(".expand_all").click(function() {
    $(this).text($(this).text() == "Shrink All" ? "Expand All" : "Shrink All");
  });
});

/* Start expand and shrink Section */


/* Start Company level Section */
$(document).on("turbolinks:load", function() {
  $(".ul_level li:first-child>.hie_item").addClass("hi_active");
  $(".corporate>.hie_item, .region>.hie_item").click(function() {
    if ($(this).hasClass("hi_active")){
      $(this).removeClass("hi_active");
    } else {
      $(".corporate>.hie_item, .region>.hie_item").removeClass("hi_active");
      $(this).addClass("hi_active");
    }
  });
});
/* End Company level Section */


/* Start date picker*/
$(document).on("turbolinks:load", function () {
  var date = new Date();
  $('#datepicker').datepicker({
    format: 'mm-dd-yyyy',
    uiLibrary: "bootstrap",
    iconsLibrary: "fontawesome",
    autoclose: true
  });
  $('#datepicker1').datepicker({
    format: 'mm-dd-yyyy',
    uiLibrary: "bootstrap",
    iconsLibrary: "fontawesome",
    autoclose: true
  });
  $('#missing_bill_datepicker').datepicker({
    format: 'mm-dd-yyyy',
    uiLibrary: "bootstrap",
    iconsLibrary: "fontawesome",
    autoclose: true
  })
  $('#datepicker-analize').datepicker({
    format: 'mm-dd-yyyy',
    uiLibrary: "bootstrap",
    iconsLibrary: "fontawesome",
    autoclose: true,
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0)
  });
  $('#water_datepicker1').datepicker({
    format: 'mm-dd-yyyy',
    uiLibrary: "bootstrap",
    iconsLibrary: "fontawesome",
    autoclose: true
  });
  $('#water_datepicker2').datepicker({
    format: 'mm-dd-yyyy',
    uiLibrary: "bootstrap",
    iconsLibrary: "fontawesome",
    autoclose: true
  });

  $(document).ready(function () {
    $('#water_datepicker1, #water_datepicker2').on('change', function () {
      var readFrom = document.getElementById('water_datepicker1').value
      var readTo = document.getElementById('water_datepicker2').value
      var date1 = new Date(readFrom);
      var date2 = new Date(readTo);
      const time1 = date1.getTime();
      const time2 = date2.getTime();

      var timeDifference = time2 - time1;
      var numberOfDays = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
      if (numberOfDays > 0 || numberOfDays == 0) {
        $('#service_days').val(numberOfDays);
      }
    });
  });

  $("#inventory_state_date").datepicker({
    todayBtn: 1,
    autoclose: true,
    format: 'mm-dd-yyyy',
    uiLibrary: "bootstrap",
    iconsLibrary: "fontawesome"
  }).on('changeDate', function (selected) {
    var startDate = new Date(selected.date.valueOf());
    $('#inventory_end_date').datepicker('setStartDate', startDate);
  });

  $("#selector").datepicker({
    todayBtn: 1,
    autoclose: true,
    format: 'mm-dd-yyyy',
    uiLibrary: "bootstrap",
    iconsLibrary: "fontawesome"
  }).datepicker("setDate", "12-31-9999"); 

  $("#inventory_end_date").datepicker({
    todayBtn: 1,
    format: 'mm-dd-yyyy',
    uiLibrary: "bootstrap",
    iconsLibrary: "fontawesome",
    assumeNearbyYear: true,
    autoclose: true
  })
  $("#bill_problem").datepicker({
    todayBtn: 1,
    format: 'mm-dd-yyyy',
    uiLibrary: "bootstrap",
    iconsLibrary: "fontawesome",
    assumeNearbyYear: true,
    startDate: new Date(),
    autoclose: true
  })
  .on('changeDate', function (selected) {
    var startDate = new Date(selected.date.valueOf());
    $('#inventory_state_date').datepicker('setEndDate', startDate);
  });

  $("#report_state_date").datepicker({
    todayBtn: 1,
    autoclose: true,
    format: 'mm-dd-yyyy',
    uiLibrary: "bootstrap",
    iconsLibrary: "fontawesome",
  }).on('changeDate', function (selected) {
    var minDate = new Date(selected.date.valueOf());
    $('#report_end_date').datepicker('setStartDate', minDate);
  });

  $("#report_end_date").datepicker({
    todayBtn: 1,
    format: 'mm-dd-yyyy',
    uiLibrary: "bootstrap",
    iconsLibrary: "fontawesome",
    autoclose: true
  })
  .on('changeDate', function (selected) {
    var maxDate = new Date(selected.date.valueOf());
    $('#inventory_state_date').datepicker('setEndDate', maxDate);
  });

});



$(document).on("turbolinks:load", function() {
 var date = new Date();
$('.date_picker').on("click",function() {
  $('#inventory_state_date').focus();
  $('#datepicker-analize').focus();
  $('#datepicke').focus();
    $('#datepicker-analize, #datepicker').datepicker({
      format: 'mm-dd-yyyy',
      uiLibrary: "bootstrap",
      iconsLibrary: "fontawesome",
      autoclose: true,
      endDate:  new Date(date.getFullYear(), date.getMonth() + 1, 0)
    });
  });
});

$(document).on("turbolinks:load", function() {
 var date = new Date();
$('.date_picker1').on("click",function() {
  $('#missing_bill_datepicker').focus();
  $('#inventory_end_date').focus();
   $('#missing_bill_datepicker').datepicker({
      format: 'mm-dd-yyyy',
      uiLibrary: "bootstrap",
      iconsLibrary: "fontawesome",
      autoclose: true,
      endDate:  new Date(date.getFullYear(), date.getMonth() + 1, 0)
    });
  });
});

$.fn.dataTable.ext.errMode = "none"; /*stop datatable warning*/

$(document).on("turbolinks:load", function () {
  $('#pay').click(function () {
    if ($('#pay').is(":checked")) {
      $('#variation').prop('disabled', false);
    } else {
      $('#variation').prop('disabled', true);
    }
  });
});

$(document).on("turbolinks:load", function() {
  $('#contactForm1').on('submit',function(event) {
    event.preventDefault()
    $.ajax({
      type: "POST",
      url: "/calendars/generate_csv",
      data: $('#contactForm1').serialize(),
      success: function (result) {
      }
    });
  });
});

$(document).on("turbolinks:load", function () {
  account_id = $("#inventory_billing_account_id").val()
  function fetch_inventory_data(account_id) {
    $.ajax({
      type: "GET",
      url: " /inventory/fetch_data",
      data: {
        account_number: account_id
      },
      async: false,
      dataType: "json",
      success: function (response) {
        $('.vendor_name').text(response.vendor_name)
        $('.vendor_code').text(response.vendor.vendor_code)
        $('.address1_data').val(response.contact.address)
        $('.address2_data').val(response.contact.address_2)
        $('.phone_number_data').val(response.contact.phone_number)
        $('.city_data').val(response.contact.city)
        $('.zip_data').val(response.contact.zip)
        var options = '';
        var states = response.states;
        $.each(states, function (index, state) {
          if (state !== null)
          {
          options += '<option value="' + state + '" name = "line_type">' + state + '</option>'; //add the option element as a string
          }
        });
        $('#account_state_data').html(options)
      },
    });
  }

  let searchParams = new URLSearchParams(window.location.search)
  let param = searchParams.get('account_number')
  if (searchParams.has("account_number")){
    var account_number = param.replace("+", " ")
    var select = document.getElementById("inventory_billing_account_id");
    for (var i = 0; i < select.options.length; i++) {
      if (select.options[i].value === account_number) {
        select.options[i].selected = true;
        fetch_inventory_data(account_number)
        break;
      }
    }
  }

  $('#inventory_billing_account_id').change(function () {
    if ($("#inventory_billing_account_id").val() != '') {
      fetch_inventory_data($(this).val())
    } else {
      $('.vendor_name').text("")
      $('.vendor_code').text("")
      $('.address1_data').val("")
      $('.address2_data').val("")
      $('.phone_number_data').val("")
      $('.city_data').val("")
      $('.zip_data').val("")
      $('#account_state_data').html("")
    }
  });
});

$(document).on("turbolinks:load", function () {
  $('#billing_account_fetch_data').change(function () {
    if ($("#billing_account_fetch_data").val() != '') {
      $.ajax({
        type: "GET",
        url: "/local_services/fetch_data",
        data: {
          account_number: $(this).val()
        },
        async: false,
        dataType: "json",
        success: function (response) {
          $('.account_summery').text(response.account_summery)
          $('.location_code_name').text(response.location_code_name)
          $('.vendor_name').text(response.vendor_name)
          $('.vendor_code').text(response.vendor.vendor_code)
          $('.location_name').text(response.location_name)
          $('.location_code').text(response.location_code)
        },
      });
    } else {
      $('.account_summery').text("")
      $('.location_code_name').text("")
      $('.vendor_name').text("")
      $('.vendor_code').text("")
      $('.location_name').text("")
      $('.location_code').text("")
    }
  });
});

$(document).on("turbolinks:load", function () {
  $('#billing_account_company_level_vendor_attributes_vendor').change(function () {
    if ($("#billing_account_company_level_vendor_attributes_vendor").val() != '') {
      $.ajax({
        type: "GET",
        url: "/billing_accounts/fetch_data",
        data: {
          vendor_id: $(this).val()
        },
        async: false,
        dataType: "json",
        success: function (response) {
          $('#billing_account_company_level_vendor_attributes_client_vendor').val(response.new_val)
        },
      });
    } else {
      $('#billing_account_company_level_vendor_attributes_client_vendor').val("")
    }
  });    
});

$(document).on("turbolinks:load", function() {
  $('.save_comment').on('click',function() {
    $(".comments_date").prop('required', true);
  });
});

$(document).on("turbolinks:load", function() {
  $('.save_another').on("click",function() {
    if($('.comments_date').prop('required')){
       $('.comments_date').removeAttr('required')
       $('.comments_date').blur();
    }
  });
});

$(document).on("turbolinks:load", function() {
  $('.save_back').on("click",function() {
    if($('.comments_date').prop('required')){
       $('.comments_date').removeAttr('required')
       $('.comments_date').blur();
      }
  });
});

document.addEventListener("turbolinks:load", function() {
  $input = $('*[data-behavior="autocomplete"]')
  var options = {
    url: function(phrase) {
      return "/inventory/search.json?account_number=" + phrase;
    },
    getValue: "account_number",
  };
  $input.easyAutocomplete(options);
});


document.addEventListener("turbolinks:load", function() {
  $input = $('*[data-wireless="autocomplete"]')
  var options = {
    url: function(phrase) {
      return "/wireless/search.json?account_number=" + phrase;
    },
    getValue: "account_number",
  };
  $input.easyAutocomplete(options);
});

document.addEventListener("turbolinks:load", function() {
  $input = $('*[data-local="autocomplete"]')
  var options = {
    url: function(phrase) {
      return "/local_services/search.json?account_number=" + phrase;
    },
    getValue: "account_number",
  };
  $input.easyAutocomplete(options);
});

document.addEventListener("turbolinks:load", function() {
  $input = $('*[data-cable="autocomplete"]')
  var options = {
    url: function(phrase) {
      return "/cable_tvs/search.json?account_number=" + phrase;
    },
    getValue: "account_number",
  };
  $input.easyAutocomplete(options);
});

document.addEventListener("turbolinks:load", function() {
  $input = $('*[data-voip="autocomplete"]')
  var options = {
    url: function(phrase) {
      return "/voip_services/search.json?account_number=" + phrase;
    },
    getValue: "account_number",
  };
  $input.easyAutocomplete(options);
});

document.addEventListener("turbolinks:load", function() {
  $input = $('*[data-broadband="autocomplete"]')
  var options = {
    url: function(phrase) {
      return "/internets/search.json?account_number=" + phrase;
    },
    getValue: "account_number",
  };
  $input.easyAutocomplete(options);
});

document.addEventListener("turbolinks:load", function() {
  $input = $('*[data-network="autocomplete"]')
  var options = {
    url: function(phrase) {
      return "/ip_networks/search.json?account_number=" + phrase;
    },
    getValue: "account_number",
  };
  $input.easyAutocomplete(options);
});

document.addEventListener("turbolinks:load", function() {
  $input = $('*[data-expense="autocomplete"]')
  var options = {
    url: function(phrase) {
      return "/billing_accounts/search_expense.json?account_number=" + phrase;
    },
    getValue: "account_number",
  };
  $input.easyAutocomplete(options);
});


$(document).on("turbolinks:load", function () {
  $("#other-phone").click(function () {
    $("#phone").data('mask').remove();
    $("#inventory_zip").data('mask').remove();
    $('#phone').removeAttr('placeholder');
    $('#inventory_zip').removeAttr('placeholder');
    $('.country_name').empty();
    $("#inventory_zip").removeAttr('maxlength');
    $("#inventory_zip").attr('maxlength', 20);
    $("#phone").attr('maxlength', 20);
  });
  $("#Usa-Phone").click(function () {
    $('#phone').mask("000-000-0000", {
      placeholder: "xxx-xxx-xxx"
    }).attr('maxlength', 10);
    $('#inventory_zip').mask("000-000-0000", {
      placeholder: "xxxxx-xxxx"
    }).attr('maxlength', 9);
    $('.country_name').html("<strong>Country</strong>:   USA")
  });
  $("#Usa-Phone-Wireless").click(function () {
    $('#phone').mask("000-000-0000", {
      placeholder: "xxx-xxx-xxx"
    }).attr('maxlength', 10);
  });

  $("#other-phone-Wireless").click(function () {
    $("#phone").data('mask').remove();
    $('#phone').removeAttr('placeholder');
  });
});

$(document).on("turbolinks:load", function() {
  $('.js-source-states').select2();
});

$(document).on("turbolinks:load", function () {
  $('.devise_change').on('change',function () {
    var devise_name = $(this).val();
    $('.devise_data').val(devise_name)
    if (devise_name != '')
    {
    $.ajax({
      type: "GET",
      url: " /wireless/new",
      data: {
        devise_name: $(this).val()
      },
      async: false,
      dataType: "json",
      success: function (response) {
        var options = '<option value=""><strong>Select Model</strong></option>';
        var company_modal = response.company;
        $.each(company_modal, function (company, models) {
           options += "Select Model"
          options += '<optgroup label="' + company + '">options</optgroup>'
          $.each(models, function (index, model) {
            options += '<option value="' + model.name + '" name = "wireless[model]">' + model.name + '</option>'; //add the option element as a string
          });
        });
        $('#datamodel').html(options)
        var companies_data = '<option value=""><strong>Select Company Name</strong></option>';
      },
    });
    }
    else
    {
      var options = '<option value=""><strong>Select Model</strong></option>';
      $('#datamodel').html(options);
      var companies_data = '<option value=""><strong>Select Company Name</strong></option>';
    }
  });
});



$(document).on("turbolinks:load", function () {
  $('.devise_change_modal').on('change',function () {
    var devise_name = $(this).val();
    $('.devise_data').val(devise_name)
    if (devise_name != '')
    {
    $.ajax({
      type: "GET",
      url: " /wireless/new",
      data: {
        devise_name: $(this).val()
      },
      async: false,
      dataType: "json",
      success: function (response) {
        var options = '<option value=""><strong>Select Model</strong></option>';
        var company_modal = response.company;
        $.each(company_modal, function (company, models) {
           options += "Select Model"
          options += '<optgroup label="' + company + '">options</optgroup>'
          $.each(models, function (index, model) {
            options += '<option value="' + model.name + '" name = "wireless[model]">' + model.name + '</option>'; //add the option element as a string
          });
        });
        $('#datamodel').html(options)
        var companies_data = '<option value=""><strong>Select Company Name</strong></option>';
        var companies = response.companies;
        $.each(companies, function (index, company) {
          companies_data += '<option value="' + company.id + '" name = "wireless[model]">' + company.name + '</option>'; //add the option element as a string
        });
        $('#model_company_id').html(companies_data)
      },
    });
    }
    else
    {
      var options = '<option value=""><strong>Select Model</strong></option>';
      $('#datamodel').html(options);
      var companies_data = '<option value=""><strong>Select Company Name</strong></option>';
      $('#model_company_id').html(companies_data)
    }
  });
});


$(document).on("turbolinks:load", function () {
  $('#model_company_id').change(function () {
    var company_name = $("#model_company_id :selected").text();
    $('.company_name').val(company_name)
  });
});

$(document).on("turbolinks:load", function () {
  $('.close-modal').on('click', function (event) {
    event.preventDefault();
    $('#modelModal').modal('hide');
    $('#companyModal').modal('hide');
    $('#exampleModal').modal('hide');
    $('#exampleModal2').modal('hide');
    $('#exampleModal4').modal('hide');
    $("#model_name").val("");
    $('#line_type_name').val("");
   $(".modal-body option:selected").prop("selected", false);
   $("#company_name").val("");
   $(".modal-body option:selected").prop("selected", false)
  });
});


$(document).on("turbolinks:load", function () {
  $('.js-example-basic-multiple').select2();
  $('.js-phone_number_line-multiple').select2();
});

$(document).on("turbolinks:load", function () {
  $('#Service_Type').on('change',function () {
    var devise_name = $(this).val();
    var client_id =  localStorage.getItem('selected_option');
    if (devise_name != ''){
      $(".show_data_filter").prop('required',true);
      $.ajax({
        type: "GET",
        url: "/reports/filter_report_date",
        data: {
          filtr_type: $(this).val(),
          client_id: client_id
        },
        async: false,
        dataType: "json",
        success: function (response) {
          var options = '';
          var values = '';
          var filter_dates = response.filter_data;
          var name = response.name;
           if (name == "acount_number" ||  name  =="location_code" || name == "summary_account_number" || name == "gl_account_number")
           {
            var values_data = ["equal to", "not equal to" ,"contains" ,"less than" ,"greater than", "between"]
             $.each(values_data, function (index, filter_data) {
              values += '<option value="' + filter_data + '" >' + filter_data + '</option>'; //add the option element as a string
            });
            $('#values').html(values);
           } else{
            var values_data = ["equal to", "not equal to" ,"contains"]
             $.each(values_data, function (index, filter_data) {
              values += '<option value="' + filter_data + '">' + filter_data + '</option>'; //add the option element as a string
            });
             $('#values').html(values);
           }
            $.each(filter_dates, function (index, filter_data) {
              options += '<option value="' + filter_data[1] + '" name = "wireless[model]">' + filter_data[0] + '</option>'; //add the option element as a string
            });
          $('#after_filter_data').html(options);
          $('#after_filter_data').attr('name',name);

        }
      });
      }
    else {
     $(".show_data_filter").removeAttr('required');
      $('#after_filter_data').html('');
      $('#after_filter_data').attr('name'," ");
    }
  });
});


$(document).on("turbolinks:load", function () {
   $(".filter_data").on('click',function () {
      if ( $('#from_').val() && $('#to_').val()!= "") {
       var string=$('#Service_Type').val()+' '+$('#values').val()+ ' '+"from"+ " " +$('#from_').val()+ " "+"to"+ " "+ $('#to_').val()
        parseInt($('#from_').val()) < parseInt($('#to_').val()) ? $('#filter').val(string) : alert(" From value should less then To value")
  } else
  {

     if ($('#filter').val() == ''  && $('#Service_Type').val()!= '')
     {
     var string=$('#Service_Type').val()+' '+$('#values').val()+ ' '+$('#after_filter_data').val()
      $('#filter').val(string)
    } else  if ($('#Service_Type').val()!= '') {
      var string=$('#Service_Type').val()+' '+$('#values').val()+ ' '+$('#after_filter_data').val()
      var data=$('#filter').val()
      var based_comdition= $("#based_comdition").val()
      $.inArray( string, data.split("AND ") )  == -1  ?
      data ==string ? alert("Please  Insert Unique Record"):  $('#filter').val(data+' '+" \n "+based_comdition+" "+string) : alert("Please  Insert Unique Record")    }
    else{
      alert("Please add select data dropdown value")
    }
  }
    });
});

$(document).on("turbolinks:load", function () {
  $('#custom_date').on('change',function () {
    var custom_date = $(this).val();
    if (custom_date != '')
    {
    $.ajax({
      type: "GET",
      url: "/reports/find_custom_date_value",
      data: {
        custom_date: $(this).val()
      },
      async: false,
      dataType: "json",
      success: function (response) {
        var Start_date = response.start_date;
        var End_date = response.end_date;
        $('#report_state_date').val(Start_date);
        $('#report_end_date').val(End_date);
        $("#report_state_date").attr('readonly', true);
        $("#report_end_date").attr('readonly', true);
      },
    });
    }
    else
    {
      $('#report_state_date').val('').removeAttr("readonly");
      $('#report_end_date').val('').removeAttr("readonly");

    }
  });
   });


$(document).on("turbolinks:load", function () {
  $('#range').hide();
  $('#values').on('change',function () {
    var values = $(this).val();
    console.log(values);
    $('#from_').val("")
    $('#to_').val("")
    if (values == 'between')
    {
    $('#range').show();
    $("#after_filter_data").prop( "disabled", true );
    }
    else
    {
      $('#range').hide();
      $("#after_filter_data").prop( "disabled", false);
    }
  });
});

/* New expense */
 $(document).on("turbolinks:load", function () {
  $("#add_expense").click(function () {
    var billing_account = $('#new_expense').val();
    if (billing_account != '') {
      data = window.location.origin+'/expenses/new?billing_id='+billing_account;
      window.location.href = data
    }
  });
});

$(document).on("turbolinks:load", function () {
  $(".clear_filter").on('click',function () {
    $("#filter").val("");
    });
  });


/* Show Hide of Client Name Option IN Add User */

$(document).on("turbolinks:load", function () {
  $('.hide_client').hide();
  $('#user_role').on('change',function () {
    if ($('#user_role').val() == "admin") {
      $('.hide_client').hide();
    } else {
      $('.hide_client').show();
    }
  });
});

/* Show Hide of sevice type for expense only */
$(document).on("turbolinks:load", function () {
  $('.hide_service').hide();
  $('#import_type').on('change',function () {
    if ($('#import_type').val() == "Expenses") {
      $('.hide_service').show();
      $('#service_type').attr('required', '');
    } else {
      $('.hide_service').hide();
      $('#service_type').removeAttr('required');
    }
  });
});

/* When click Directly to the import expense in expense index */
$(document).on("turbolinks:load", function () {
  if ((window.location.pathname == '/import') && (window.location.search == '?import_type=Expenses')){
    $('.hide_service').show();
    $('#service_type').attr('required', '');
  }
});

/* Close Location Check Uncheck test */
$(document).on("turbolinks:load", function () {
  $('.close_location').hide();
  $('#company_level_close_location').on('change',function () {
    var marked = $('#company_level_close_location').is(':checked');
    if (marked == true) {
      $('.close_this').show();
    } else {
      $('.close_this').hide();
    }
  });
});

$(document).on("turbolinks:load", function () {
  $('.reactive_this').hide();
  $('#company_level_close_location').on('change',function () {
    var marked = $('#company_level_close_location').is(':checked');
    if (marked == false) {
      $('.reactive_this').show();
    } else {
      $('.reactive_this').hide();
    }
  });
});

/* Disable datepicker when remind on unchecked */
$(document).on("turbolinks:load", function () {
  $('#billing_account_remind_on_date').on('change',function () {
    var marked = $('#billing_account_remind_on_date').is(':checked');
    if (marked == true) {
      $('.calender_disable').prop( "disabled", false );
      $('.close_this').show();
    } else {
      $('.calender_disable').prop( "disabled", true );
    }
  });
});

// Back to the previous URL
$(document).on("turbolinks:load", function () {
  $(".back_url").click(function () {
    history.back();
  });
});

// For handling Expense Filter
$(document).on("turbolinks:load", function () {
  $('.select_option').click(function () {
    var run_date = $("#flexRadioDefault1").is(":checked")
    var run_trans = $("#flexRadioDefault2").is(":checked");
    var all_exp = $("#flexRadioDefault3").is(":checked");
    if (run_date == true) {
      $('.run_date').show();
      $('.run_trans').hide();
      $('.clear_date').attr('required', true);
    } else if (run_trans == true) {
      $('.run_trans').show();
      $('.run_date').hide();
      $('.clear_date').val("");
      $('.clear_date').attr('required', false);
    } else {
      $('.run_trans').hide();
      $('.run_date').hide();
      $('.clear_date').attr('required', false);
    }
    if (run_date == true || run_trans == true || all_exp == true ) {
      $('.enable').show();
    } else {
      $('.enable').hide();
    }
  });
});

// Romiving selection of radio on close or cancle modal
$(document).on("turbolinks:load", function () {
  $(".check_close").click(function () {
    $( "#flexRadioDefault1" ).prop( "checked", false );
    $( "#flexRadioDefault2" ).prop( "checked", false );
    $( "#flexRadioDefault3" ).prop( "checked", false );
    $('.run_trans').hide();
    $('.run_date').hide();
    $('.enable').hide();
  });
});




$(document).on("turbolinks:load", function () {
  $(".qa_expense_template_item .number_box input").each(function() {
    $(this).keyup(function(){
      calculateSum();
    });
  });
  function calculateSum() {
    var sum = 0;
    $(".qa_expense_template_item .number_box input").each(function() {
      if(!isNaN(this.value) && this.value.length!=0) {
        if (this.className.includes('credit')) {
          sum -= parseFloat(this.value)
        } else {
          sum += parseFloat(this.value);
      }}
    });
    $('#expense_total').val(sum.toFixed(2));
  }
});

$(document).on("turbolinks:load", function () {
  $('#account-service-type').on('change',function () {
    var serviceType = $("#account-service-type :selected").val()
    if (serviceType == 'Electric') {
      $('.drop-box').find('select[name="billing_account[account_data][unit]"]').empty().append(
        $('<option>').val('KW-Kilowatt').text('KW-Kilowatt')
      );
    } else {
      $('.drop-box').find('select[name="billing_account[account_data][unit]"]').empty().append(
        $('<option>').val('G-Gallon').text('G-Gallon'),
        $('<option>').val('CF-Cubic Feet').text('CF-Cubic Feet'),
        $('<option>').val('T-Therms').text('T-Therms')
      );
    }
    if(serviceType == 'Gas' || serviceType == 'Electric' || serviceType == 'Water/Sewer') {
      $(".new_account_data").show()
    } else {
      $(".new_account_data").hide()
    }
  });
  var editPage = window.location.href.includes('edit')
  if (editPage) {
    $('#account-service-type').trigger("change");
  }
});

/*Added spinner for closing CSV download starts*/

$(document).on("turbolinks:load", function () {
  var downloadButton = $("#download-csv-button");
  var spinner = $("#spinner");
  spinner.hide();

  downloadButton.on("click", function(event) {
    event.preventDefault();
    spinner.show();

    $.ajax({
      url: downloadButton.attr("href"),
      method: "GET",
      xhrFields: {
        responseType: "blob"
      },
      success: function(response) {
        var blob = new Blob([response], { type: "text/csv" });
        var url = URL.createObjectURL(blob);

        var a = $("<a></a>")
          .attr("href", url)
          .attr("download", "closing.csv")
          .css("display", "none");
        $("body").append(a);
        a[0].click();
        a.remove();
        URL.revokeObjectURL(url);
      },
      complete: function() {
        spinner.hide();
      }
    });
  });
});

/*Added spinner for closing CSV download ends*/